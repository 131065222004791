import { MenuItem } from "@mui/material";
import {
    gridColumnDefinitionsSelector,
    GridExportMenuItemProps,
    gridVisibleColumnFieldsSelector,
    useGridApiContext,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { utils, writeFile } from "xlsx";

import { getJson } from "./datagrid-utils";

export function ExcelExportMenuItem(
    props: GridExportMenuItemProps<Record<string, never>>,
) {
    const { t } = useTranslation("form");
    const apiRef = useGridApiContext();

    const { hideMenu } = props;

    return (
        <MenuItem
            onClick={() => {
                const visibleColumnsField =
                    gridVisibleColumnFieldsSelector(apiRef);
                const columnHeaderNames = gridColumnDefinitionsSelector(apiRef)
                    .filter((column) =>
                        visibleColumnsField.includes(column.field),
                    )
                    .map((column) => column.headerName);
                const worksheet = utils.json_to_sheet(
                    getJson(apiRef, visibleColumnsField),
                );
                utils.sheet_add_aoa(worksheet, [columnHeaderNames], {
                    origin: "A1",
                });
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, "Export");
                writeFile(workbook, "export.xlsx", { compression: true });
                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            {t("datagrid.downloadExcel")}
        </MenuItem>
    );
}
