/* eslint-disable perfectionist/sort-objects */
import { ErrorCard } from "@/components/general/cards";
import { STALETIME } from "@/default";
import { CircularProgress, Container, Grid2 } from "@mui/material";
import Form from "@rjsf/mui";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";

import { Components } from "../../commonapi.openapi";
import { getCommonApiClient } from "../api";
import { companyIdAtom, customerIdAtom } from "../atoms";
import formRoute from "../routes/form";

const demoSchema: RJSFSchema = {
    properties: {
        job_function: {
            required: ["true"],
            title: "Job Function",
            type: "string",
        },
        description: {
            required: ["true"],
            title: "Description",
            type: "string",
        },
        no_mailing: {
            title: "No Mailing",
            type: "string",
        },
        unique_presence: {
            title: "Unique Presence",
            type: "string",
        },
    },
    required: ["job_function", "description"],
    title: "Job Function",
    type: "object",
};

export function FormPage() {
    const { t } = useTranslation("form");
    const { formId } = formRoute.useParams();
    const company = useAtomValue(companyIdAtom);
    const customer = useAtomValue(customerIdAtom);
    const navigate = useNavigate({ from: "/form/$formId" });

    const {
        data: formUISchema,
        isPending: isUISchemaPending,
        isError: isUISchemaError,
        error: UISchemaError,
    } = useQuery({
        queryFn: async () => {
            const client = await getCommonApiClient();
            const res = await client.get_react_json_form({
                form_name: "edit_job_function",
            });
            return res.data as unknown as { message: { ui: UiSchema } };
        },
        queryKey: ["function_type_form_schema", formId],
        staleTime: STALETIME * 10,
    });

    const {
        data: formData,
        isPending: isformDataPending,
        isError: isformDataError,
        error: formDataError,
    } = useQuery({
        queryFn: async () => {
            const client = await getCommonApiClient();
            const res = await client.get_function_type({
                cash_customer: customer,
                company_id: company,
                function_type_id: formId,
            });

            return res.data;
        },
        queryKey: ["function_type", formId, company, customer],
        staleTime: STALETIME,
    });

    const mutation = useMutation({
        mutationFn: async (input: RJSFSchema) => {
            const client = await getCommonApiClient();
            await client.put_function_type(
                {
                    cash_customer: customer,
                    company_id: company,
                    function_type_id: formId,
                },
                {
                    function_type_data:
                        input.formData as Components.Schemas.FunctionTypeRequest,
                },
            );
        },
        onSuccess: async () => {
            await navigate({
                to: "/overview/$type",
                params: {
                    type: "function_type",
                },
            });
        },
    });

    if (isUISchemaPending || isformDataPending) {
        return <CircularProgress />;
    }

    if (isUISchemaError) {
        return <ErrorCard error={UISchemaError} />;
    }

    if (isformDataError) {
        return <ErrorCard error={formDataError} />;
    }

    return formData && formUISchema ? (
        <Grid2
            alignItems="center"
            container
            justifyContent="center"
            marginBottom={4}
        >
            <Grid2>
                <Container maxWidth="sm">
                    <h3>
                        {t("edit")} Job Function: {formData.job_function}
                    </h3>
                    <Form
                        formData={formData}
                        onSubmit={(inputData) => {
                            mutation.mutate(inputData);
                        }}
                        schema={demoSchema}
                        uiSchema={formUISchema.message.ui}
                        validator={validator}
                    />
                </Container>
            </Grid2>
        </Grid2>
    ) : (
        <CircularProgress />
    );
}
