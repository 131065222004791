import { Card, CardContent, CardHeader, Typography } from "@mui/material";

export function ErrorCard({ error }: { error: Error }) {
    return (
        <Card variant="outlined">
            <CardHeader title="Error" />
            <CardContent>
                <Typography color="error" variant="h6">
                    {error.message}
                </Typography>
                <Typography variant="caption">{error.stack}</Typography>
            </CardContent>
        </Card>
    );
}
