import type { ChipProps } from "@mui/material";

import { Icon } from "@/theme";
import { Chip, CircularProgress } from "@mui/material";

export function ErrorChip({ error }: { error: Error }) {
    return (
        <Chip
            color="error"
            icon={<Icon name="Error" />}
            label={error.message}
            sx={{ margin: "1em" }}
        />
    );
}

export function LoadingChip({ icon, label, sx, ...props }: ChipProps) {
    return (
        <Chip
            icon={icon ?? <CircularProgress size="1rem" />}
            label={label ?? "Loading menu"}
            sx={sx ?? { margin: "1em" }}
            {...props}
        />
    );
}
