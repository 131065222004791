import { currencyAtom } from "@/atoms";
import { ErrorCard } from "@/components/general/cards";
import { OptionDrawer } from "@/components/general/option-drawer";
import {
    type BalanceDataMock,
    BalanceTable,
    BalanceTableToolbar,
} from "@components/balance/balance-table";
import { CancelButton, SaveButton } from "@components/general/buttons";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid2,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

enum Direction {
    SideBySide = 6,
    Stacked = 12,
}

const activa: BalanceDataMock = {
    categories: [
        {
            finalBalancePeriod1: 750,
            finalBalancePeriod2: 700,
            items: [
                {
                    finalBalancePeriod1: 150,
                    finalBalancePeriod2: 200,
                    name: "Grond / Gebouwen",
                    totalDifference: -50,
                },
                {
                    finalBalancePeriod1: 400,
                    finalBalancePeriod2: 400,
                    name: "Inventaris",
                    totalDifference: 0,
                },
                {
                    finalBalancePeriod1: 200,
                    finalBalancePeriod2: 200,
                    name: "Vervoersmiddelen",
                    totalDifference: 0,
                },
            ],
            name: "Materiële activa",
            totalDifference: -50,
        },
        {
            finalBalancePeriod1: 1600,
            finalBalancePeriod2: 1400,
            items: [
                {
                    finalBalancePeriod1: 1600,
                    finalBalancePeriod2: 1400,
                    name: "Voorraad",
                    totalDifference: 200,
                },
            ],
            name: "Voorraden",
            totalDifference: 200,
        },
        {
            finalBalancePeriod1: 6000,
            finalBalancePeriod2: 3000,
            items: [
                {
                    finalBalancePeriod1: 3000,
                    finalBalancePeriod2: 6000,
                    name: "Liquide middelen",
                    totalDifference: 3000,
                },
                {
                    finalBalancePeriod1: 3000,
                    finalBalancePeriod2: 3000,
                    name: "Grote zak geld",
                    totalDifference: 0,
                },
            ],
            name: "Liquide middelen",
            totalDifference: 3000,
        },
    ],
    totals: {
        finalBalancePeriod1: 8350,
        finalBalancePeriod2: 5100,
        totalDifference: -3250,
    },
};

const passiva: BalanceDataMock = {
    categories: [
        {
            finalBalancePeriod1: 750,
            finalBalancePeriod2: 750,
            items: [
                {
                    finalBalancePeriod1: 750,
                    finalBalancePeriod2: 750,
                    name: "Eigen vermogen",
                    totalDifference: 0,
                },
                {
                    finalBalancePeriod1: 750,
                    finalBalancePeriod2: 750,
                    name: "Resultaat",
                    totalDifference: 0,
                },
            ],
            name: "Eigen vermogen",
            totalDifference: 0,
        },
        {
            finalBalancePeriod1: 2000,
            finalBalancePeriod2: 1600,
            items: [
                {
                    finalBalancePeriod1: 2000,
                    finalBalancePeriod2: 1600,
                    name: "Langlopende schulden",
                    totalDifference: 0,
                },
            ],
            name: "Lang vreemd vermogen",
            totalDifference: 400,
        },
        {
            finalBalancePeriod1: 750,
            finalBalancePeriod2: 760,
            items: [
                {
                    finalBalancePeriod1: 300,
                    finalBalancePeriod2: 300,
                    name: "Kortlopende schulden",
                    totalDifference: 0,
                },
                {
                    finalBalancePeriod1: 450,
                    finalBalancePeriod2: 460,
                    name: "Overige kortlopende schulden",
                    totalDifference: -10,
                },
            ],
            name: "Kort vreemd vermogen",
            totalDifference: -10,
        },
    ],
    totals: {
        finalBalancePeriod1: 5550,
        finalBalancePeriod2: 5450,
        totalDifference: 50,
    },
};

const nearlyEmptyBalanceDataMock: BalanceDataMock = {
    categories: [
        {
            finalBalancePeriod1: 0,
            finalBalancePeriod2: 0,
            items: [],
            name: "",
            totalDifference: 0,
        },
    ],
    totals: {
        finalBalancePeriod1: 0,
        finalBalancePeriod2: 0,
        totalDifference: 0,
    },
};

async function getBalance(currency: string) {
    // Simulate a network request
    await new Promise((resolve) => setTimeout(resolve, 1000));

    return {
        activa: currency === "EUR" ? activa : nearlyEmptyBalanceDataMock,
        passiva: currency === "EUR" ? passiva : nearlyEmptyBalanceDataMock,
    };
}

const gridSizeAtom = atom<Direction>(Direction.SideBySide);

export function BalancePage() {
    const currency = useAtomValue(currencyAtom);
    const { data, error, isError, isPending } = useQuery({
        queryFn: async () => {
            return (await getBalance(currency)) as {
                activa: BalanceDataMock;
                passiva: BalanceDataMock;
            };
        },
        queryKey: ["balance", currency],
        staleTime: Infinity,
    });
    const { t } = useTranslation("balance");
    const gridSize = useAtomValue(gridSizeAtom);
    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    if (isPending) {
        return <CircularProgress />;
    }

    if (isError) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Card sx={{ margin: "0 auto", width: "90%" }}>
                <CardContent>
                    <Grid2 container>
                        <Grid2 paddingY={"1em"} size={12}>
                            <BalanceTableToolbar toggleDrawer={toggleDrawer} />
                        </Grid2>
                        <Grid2
                            data-testid="tableGrid"
                            minHeight={"25em"}
                            paddingY={"1em"}
                            size={gridSize}
                        >
                            {data.activa && (
                                <BalanceTable
                                    data={data.activa}
                                    label="Activa"
                                />
                            )}
                        </Grid2>
                        <Grid2
                            minHeight={"25em"}
                            paddingY={"1em"}
                            size={gridSize}
                        >
                            {data.passiva && (
                                <BalanceTable
                                    data={data.passiva}
                                    label="Passiva"
                                />
                            )}
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Card>
            <OptionDrawer
                description={t("options.adjustBalanceMessage")}
                icon="ControlPanel"
                open={open}
                title={t("options.adjustBalance")}
                toggleDrawer={toggleDrawer}
            >
                <OptionDrawerContent />
            </OptionDrawer>
        </>
    );
}

function OptionDrawerContent() {
    const { t } = useTranslation("balance");
    const [alignment, setAlignment] = useState<
        "side-by-side" | "stacked" | null
    >("side-by-side");
    const borderStyle = { borderBottom: "1px dashed #E0E0E0" };
    const setGridSize = useSetAtom(gridSizeAtom);
    const setCurrency = useSetAtom(currencyAtom);

    const handleAlignment = (
        _event: React.MouseEvent<HTMLElement>,
        newAlignment: "side-by-side" | "stacked" | null,
    ) => {
        if (newAlignment === "stacked") {
            setGridSize(Direction.Stacked);
        } else {
            setGridSize(Direction.SideBySide);
        }
        setAlignment(newAlignment);
    };

    return (
        <Grid2 container gap={2}>
            <Grid2 paddingBottom={2} size={12} sx={borderStyle}>
                <Typography variant="h6">{t("options.display")}</Typography>
                <ToggleButtonGroup
                    aria-label="balance alignment"
                    exclusive
                    onChange={handleAlignment}
                    value={alignment}
                >
                    <ToggleButton value="side-by-side">
                        {t("alignment.side-by-side")}
                    </ToggleButton>
                    <ToggleButton value="stacked">
                        {t("alignment.stacked")}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid2>
            <Grid2 paddingBottom={2} size={12} sx={borderStyle}>
                <Typography variant="h6">Tijdspanne eindsaldo</Typography>
                <Grid2 container gap={1}>
                    <Grid2>
                        <OptionDrawerSelect
                            items={[
                                { label: "2024", value: "2024" },
                                { label: "2023", value: "2023" },
                                { label: "2022", value: "2022" },
                            ]}
                            label="Boekjaar"
                        />
                        <OptionDrawerSelect
                            items={[
                                { label: "Januari", value: "januari" },
                                { label: "Februari", value: "february" },
                                { label: "Maart", value: "march" },
                            ]}
                            label="Vanaf periode"
                        />
                    </Grid2>
                    <Grid2>
                        <OptionDrawerSelect
                            items={[
                                { label: "Jaar", value: "year" },
                                { label: "Kwartaal", value: "quarter" },
                                { label: "Maand", value: "month" },
                            ]}
                            label="Saldo tonen per"
                        />
                        <OptionDrawerSelect
                            items={[
                                { label: "Oktober", value: "most-recent" },
                                { label: "September", value: "september" },
                                { label: "Augustus", value: "august" },
                            ]}
                            label="Tot en met periode"
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 paddingBottom={2} size={12} sx={borderStyle}>
                <Typography variant="h6">Vergelijken</Typography>
                <Grid2 container>
                    <Grid2 size={6}>
                        <Stack>
                            <OptionDrawerSelect
                                items={[
                                    {
                                        label: "Eindsaldo 2023",
                                        value: "end-balance-2023",
                                    },
                                    {
                                        label: "Eindsaldo 2022",
                                        value: "end-balance-2022",
                                    },
                                    {
                                        label: "Eindsaldo 2021",
                                        value: "end-balance-2021",
                                    },
                                ]}
                                label="Vergelijken met eerder boekjaar"
                            />
                            <Typography
                                sx={{ marginLeft: 1 }}
                                variant="caption"
                            >
                                Selecteer een jaar waarmee je het gekozen
                                eindsaldo mee wil vergelijken.
                            </Typography>
                        </Stack>
                    </Grid2>
                    <Grid2 size={6}>
                        <OptionDrawerSelect
                            items={[
                                { label: "Bedrag in €", value: "euro" },
                                { label: "Bedrag in %", value: "percentage" },
                            ]}
                            label="Verschil tonen als"
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 size={12}>
                <Typography variant="h6">Overige</Typography>
                <Grid2 container gap={1}>
                    <Grid2>
                        <OptionDrawerSelect
                            items={[
                                { label: "Euro € (standaard)", value: "EUR" },
                                {
                                    label: "Dollars $",
                                    value: "USD",
                                },
                            ]}
                            label="Valuta"
                            onChange={(currency) => {
                                setCurrency(currency);
                            }}
                        />
                    </Grid2>
                    <Grid2>
                        <OptionDrawerSelect
                            items={[
                                { label: "2 decimalen", value: "2" },
                                { label: "3 decimalen", value: "3" },
                                { label: "4 decimalen", value: "4" },
                            ]}
                            label="Afronden op"
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2
                alignContent="end"
                display="flex"
                gridColumn={12}
                justifyContent="end"
                size="grow"
                sx={{
                    borderTop: "1px solid #E0E0E0",
                    paddingTop: 2,
                }}
            >
                <Stack direction="row" gap={1}>
                    <CancelButton />
                    <SaveButton />
                </Stack>
            </Grid2>
        </Grid2>
    );
}

function OptionDrawerSelect({
    items,
    label,
    onChange,
}: {
    items: { label: string; value: string }[];
    label: string;
    onChange?: (value: string) => void;
}) {
    const [value, setValue] = useState<string>(items[0].value);
    const handleChange = (event: SelectChangeEvent) => {
        onChange?.(event.target.value);
        setValue(event.target.value);
    };
    return (
        <>
            <Box sx={{ marginLeft: 1 }}>
                <strong>{label}</strong>
            </Box>
            <FormControl
                size="small"
                sx={{ m: 1, minWidth: "10em" }}
                variant="outlined"
            >
                <Select
                    labelId={`${label}-label`}
                    onChange={handleChange}
                    value={value}
                >
                    {items.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
